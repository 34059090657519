import React, { useState } from "react";
import "../pages/style.css";

// ** MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import mpTracker from '../library/mixpanel';
import {ErrorBox } from "../components/CustomElements";

const API_URL = process.env.REACT_APP_API_URL;

export default function SubscriptionItem({
  subscriptionMonths,
  text2,
  text3,
  subscriptionPrice,
  stripePriceId,
  stytchId,
  tagging,
  trialEnd,
  customerEmail,
  bestValue,
  salePrice,
}) {

  const [error, setError] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents default form submission before mixpanel
    // Tracking logic after form submission
    mpTracker.track(tagging); // mixpanel 
    // event.target.submit(); // Submit the form after tracking if required

    const response = await fetch(`${API_URL}/v1/payment/create-checkout-session`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        stytchId: stytchId,
        couponCode: localStorage.getItem("couponCode"),
        price_id: stripePriceId,
        trial_end: trialEnd,
        customerEmail: customerEmail,
        salePrice: salePrice,
        tagging: tagging,
      }),
    });

    const data = await response.json();
    if (response.status === 200) {
      window.location.href = data.url;
      return true
    }
    setError("Please reload page and try again");
  };
  return (
    <form
      name="checkout-form"
      action={`${API_URL}/v1/payment/create-checkout-session`}
      method="POST"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="stytchId" value={`${stytchId}`} />
      <input
        type="hidden"
        name="couponCode"
        value={`${localStorage.getItem("couponCode")}`}
      />{" "}
      <input type="hidden" name="price_id" value={`${stripePriceId}`} />
      <input type="hidden" name="trial_end" value={`${trialEnd}`} />
      <input type="hidden" name="customerEmail" value={`${customerEmail}`} />
      <input type="hidden" name="salePrice" value={`${salePrice}`} />
      <input type="hidden" name="tagging" value={`${tagging}`} />
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit}
        style={{
          background: "none",
          marginBottom: 20,
          border: "none",
          cursor: "pointer",
        }}
      >
        <Grid
          item
          sm="12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1px solid #33c4EB",
            background: "#474747",
            borderRadius: "8px",
            padding: 15,
            minWidth: "350px",
            height: "80px",
          }}
        >
          {bestValue ? (
            <Paper
              elevation={3}
              style={{
                position: "relative",
                top: "-25px",
                padding: "3px 5px",
                height: "19px",
                borderRadius: "8px",
                background: "rgb(51, 196, 235)",
              }}
            >
              <Typography
                className="open"
                style={{
                  color: "#000",
                  fontWeight: "800",
                  fontSize: "10px",
                  fontFamily: "Open Sans",
                }}
              >
                BEST VALUE
              </Typography>
            </Paper>
          ) : (
            <></>
          )}
          <Box
            style={{
              textAlign: "left",
              marginRight: 25,
              position: bestValue ? "absolute" : "relative",
            }}
          >
            <Typography
              className="spartan"
              textTransform={"uppercase"}
              sx={{
                color: "#fff",
                fontWeight: 700,
                fontSize: 24,
                fontFamily: "League Spartan",
              }}
            >
              {subscriptionMonths}{" "}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 300,
                fontSize: 13,
                mt: -1,
                fontFamily: "Open Sans",
              }}
            >
              {text2}{" "}
            </Typography>
          </Box>
          <Box
            style={{
              textAlign: "right",
              marginLeft: 25,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 300,
                fontSize: 8,
                fontFamily: "Open Sans",
              }}
            >
              {text3}{" "}
            </Typography>
            <Box style={{ display: "flex" }}>
              <Typography
                textTransform={"uppercase"}
                sx={{
                  color: "#fff",
                  textDecoration: salePrice ? "line-through" : "none",
                  fontWeight: 700,
                  fontSize: 16,
                  fontFamily: "League Spartan",
                  mr: salePrice ? 0.5 : 0,
                }}
              >
                {subscriptionPrice}
              </Typography>
              {salePrice ? (
                <Typography
                  textTransform={"uppercase"}
                  sx={{
                    color: "#33C4EB",
                    fontWeight: 700,
                    fontSize: 24,
                    fontFamily: "League Spartan",
                  }}
                >
                  {salePrice}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Grid>
      </button>
      <ErrorBox variant="body2">{error}</ErrorBox>
    </form>
  );
}
